<template>
  <div class="row">
    <div class="col-md-6">
      <b-table-simple class="table table-sm table-borderless table-vertical-center mb-0">
        <b-thead>
          <b-tr>
            <b-th class="p-0 w-50px"></b-th>
            <b-th class="p-0 min-w-200px"></b-th>
            <b-th class="p-0 min-w-100px"></b-th>
            <b-th class="p-0 min-w-40px"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.AUSGESTELLT') }}: </b-td>
            <b-td>
              {{ parseDate(teilnehmer.passAusstellungsdatum.value || teilnehmer.passAusstellungsdatum) }}
              <PassCheckIcon
                v-if="
                  teilnehmer.passAusstellungsdatum && teilnehmer.passAusstellungsdatum.hasOwnProperty('value')
                "
                :passdatenAttribute="teilnehmer.passAusstellungsdatum"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.GUELTIGBIS') }}: </b-td>
            <b-td>
              {{ parseDate(teilnehmer.passGueltigkeitsdatum.value || teilnehmer.passGueltigkeitsdatum) }}
              <PassCheckIcon
                v-if="
                  teilnehmer.passGueltigkeitsdatum && teilnehmer.passGueltigkeitsdatum.hasOwnProperty('value')
                "
                :passdatenAttribute="teilnehmer.passGueltigkeitsdatum"
              ></PassCheckIcon>
              <span
                :title="$t('KUNDEN.PASS7MONTATEGIELTIG')"
                v-b-tooltip.hover
                class="bg-warning rounded p-1"
                v-if="isPassLessThanSevenMonthsValid"
              >
                <i class="flaticon2-exclamation icon-1x text-white"></i>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('VORGAENGE.AUSSTELLUNGSORT') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passOrt') }}
              <PassCheckIcon
                v-if="teilnehmer.passOrt && teilnehmer.passOrt.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.passOrt"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('VORGAENGE.AUSSTELLUNGSLAND') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passLand') }}
              <PassCheckIcon
                v-if="teilnehmer.passLand && teilnehmer.passLand.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.passLand"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.geburtsortRequired') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'geburtsort') }}
              <PassCheckIcon
                v-if="teilnehmer.geburtsort && teilnehmer.geburtsort.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.geburtsort"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div class="col-md-6">
      <b-table-simple class="table table-sm table-borderless table-vertical-center">
        <b-thead>
          <b-tr>
            <b-th class="p-0 w-50px"></b-th>
            <b-th class="p-0 min-w-200px"></b-th>
            <b-th class="p-0 min-w-100px"></b-th>
            <b-th class="p-0 min-w-40px"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.NUMMER') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passNummer') }}
              <PassCheckIcon
                v-if="teilnehmer.passNummer && teilnehmer.passNummer.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.passNummer"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('REISEN.COMMON.NAME') }}: </b-td>
            <b-td>
              {{ teilnehmer.passName || '-' }}
              <PassCheckIcon
                :passdatenAttribute="{ required: false, provided: teilnehmer.passName }"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.passartRequired') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passArt') }}
              <PassCheckIcon
                v-if="teilnehmer.passArt && teilnehmer.passArt.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.passArt"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.geschlechtRequired') }}: </b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'geschlecht') }}
              <PassCheckIcon
                v-if="teilnehmer.geschlecht && teilnehmer.geschlecht.hasOwnProperty('value')"
                :passdatenAttribute="teilnehmer.geschlecht"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import PassCheckIcon from '@/components/vorgaenge/passdaten-check-icon.vue';
const moment = require('moment');

export default {
  components: { PassCheckIcon },

  props: {
    reiseterminStartdatum: { type: String },
    teilnehmer: { type: Object },
  },
  computed: {
    isPassLessThanSevenMonthsValid() {
      if (
        this.teilnehmer.passGueltigkeitsdatum &&
        this.teilnehmer.passGueltigkeitsdatum.value &&
        this.reiseterminStartdatum
      ) {
        return (
          moment(this.teilnehmer.passGueltigkeitsdatum.value).diff(
            moment(this.reiseterminStartdatum),
            'months'
          ) < 7
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    parseDate(date) {
      const dateToParse = date.hasOwnProperty('value') ? date.value : date;
      return dateToParse ? this.$options.filters.date(dateToParse) : '-';
    },
    getTeilnehmerDataByKey(teilnehmer, key) {
      return teilnehmer[key] && teilnehmer[key].hasOwnProperty('value')
        ? teilnehmer[key].value || '-'
        : teilnehmer[key] || '-';
    },
  },
};
</script>

<style lang="scss" scoped>
table td {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
