<template>
  <div>
    <div v-if="editor && !isDisabled">
      <div class="d-flex toolbar">
        <div
          variant="none"
          class="toolbar-button"
          @click="editor.can().undo() ? editor.chain().focus().undo().run() : undefined"
          :disabled="!editor.can().undo()"
          :class="!editor.can().undo() ? 'disabled' : ''"
          :style="{ opacity: !editor.can().undo() ? 0.5 : 1 }"
        >
          <i class="fas fa-undo fa-lg"></i>
        </div>
        <div
          class="toolbar-button"
          @click="editor.can().redo() ? editor.chain().focus().redo().run() : undefined"
          :disabled="!editor.can().redo()"
          :class="!editor.can().redo() ? 'disabled' : ''"
          :style="{ opacity: !editor.can().redo() ? 0.5 : 1 }"
        >
          <i class="fas fa-redo fa-lg"></i>
        </div>
        <span class="vertical-seperator"></span>

        <div
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          class="toolbar-button"
        >
          <i class="fas fa-bold fa-lg"></i>
        </div>
        <div
          @click="editor.chain().focus().toggleItalic().run()"
          class="toolbar-button"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <i class="fas fa-italic fa-lg"></i>
        </div>
        <div
          @click="editor.chain().focus().toggleUnderline().run()"
          class="toolbar-button"
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <i class="fas fa-underline fa-lg"></i>
        </div>
        <span class="vertical-seperator"></span>
        <div class="toolbar-button d-flex justify-items-center align-items-center">
          <b-button class="p-0 w-100 h-100" :id="'color-palette-' + uniqueId" variant="none" ref="button">
            <i class="fas fa-palette fa-lg p-0" :style="{ color: colorSelected }"></i>
          </b-button>

          <b-popover
            :target="'color-palette-' + uniqueId"
            placement="bottom"
            triggers="focus"
            custom-class="color-palette"
          >
            <div v-for="color in colors" :key="'palette-color-' + color" class="palette-color">
              <div
                style="width: 24px; height: 24px; border-radius: 3px; cursor: pointer"
                :style="{
                  border: colorSelected === color ? '1px solid #000' : '1px solid #bfc4c9',
                  opacity: colorSelected === color ? '1px solid #000' : '1px solid #bfc4c9',
                  backgroundColor: color,
                }"
                @click="clickPaletteColor(color)"
              ></div>
            </div>
          </b-popover>
        </div>
        <div class="toolbar-button" @click="editor.chain().focus().setColor(null).run()">
          <i class="fas fa-tint-slash fa-lg"></i>
        </div>
        <span class="vertical-seperator"></span>
        <div
          class="toolbar-button"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <i class="fas fa-list-ul fa-lg"></i>
        </div>
        <div
          class="toolbar-button"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <i class="fas fa-list-ol fa-lg"></i>
        </div>
        <span class="vertical-seperator"></span>
        <div class="toolbar-button d-flex justify-items-center align-items-center">
          <b-button class="p-0 w-100 h-100" :id="'table-' + uniqueId" variant="none">
            <i class="fas fa-table fa-lg p-0"></i>
          </b-button>

          <b-popover :target="'table-' + uniqueId" placement="bottom" triggers="focus">
            <div class="d-flex" style="flex-direction: column; gap: 4px">
              <div class="d-flex" style="gap: 10px">
                <b-form-group label="Zeilen">
                  <b-input v-model="tableRows"></b-input>
                </b-form-group>
                <b-form-group label="Spalten">
                  <b-input v-model="tableCols"></b-input>
                </b-form-group>
              </div>
              <b-button
                size="sm"
                @click="
                  editor
                    .chain()
                    .focus()
                    .insertTable({ rows: tableRows, cols: tableCols, withHeaderRow: false })
                    .run()
                "
              >
                Erstelle Tabelle
              </b-button>
            </div>
          </b-popover>
        </div>
        <div style="display: flex; gap: 4px">
          <div
            class="table-edit-buttons"
            @click="editor.chain().focus().addRowAfter().run()"
            v-if="isCursorInTable"
          >
            <b>+ Zeile</b>
          </div>
          <div
            class="table-edit-buttons"
            @click="editor.chain().focus().deleteRow().run()"
            v-if="isCursorInTable"
          >
            <b>- Zeile</b>
          </div>
          <div
            class="table-edit-buttons"
            @click="editor.chain().focus().addColumnAfter().run()"
            v-if="isCursorInTable"
          >
            <b>+ Spalte</b>
          </div>
          <div
            class="table-edit-buttons"
            @click="editor.chain().focus().deleteColumn().run()"
            v-if="isCursorInTable"
          >
            <b>- Spalte</b>
          </div>
          <div
            class="table-edit-buttons"
            @click="editor.chain().focus().deleteTable().run()"
            v-if="isCursorInTable"
          >
            <b>- Tabelle</b>
          </div>
        </div>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import suggestion from './suggestion.js';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
    },
    placeholder: { type: String },
    isDisabled: { type: Boolean, default: false },
    disableMentions: { type: Boolean, default: false },
  },
  computed: {
    colorSelected() {
      return this.editor?.getAttributes('textStyle')?.color;
    },
    isCursorInTable() {
      return this.editor?.isActive('table');
    },
  },
  data() {
    return {
      editor: null,
      popup: null,
      component: null,
      uniqueId: Math.random().toString(36).substring(2, 9),
      colors: [
        'rgb(0, 0, 0)',
        'rgb(77, 77, 77)',
        'rgb(153, 153, 153)',
        'rgb(230, 230, 230)',
        'rgb(255, 255, 255)',
        'rgb(230, 77, 77)',
        'rgb(230, 153, 77)',
        'rgb(230, 230, 77)',
        'rgb(153, 230, 77)',
        'rgb(77, 230, 77)',
        'rgb(77, 230, 153)',
        'rgb(77, 230, 230)',
        'rgb(77, 153, 230)',
        'rgb(77, 77, 230)',
        'rgb(153, 77, 230)',
      ],
      tableRows: 3,
      tableCols: 3,
    };
  },
  watch: {
    value(newValue) {
      if (this.editor && this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue);
      }
    },
    isDisabled(newValue) {
      this.editor.setOptions({ editable: !newValue });
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      editable: !this.isDisabled,
      extensions: [
        StarterKit,
        Underline,
        Color,
        TextStyle,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        this.disableMentions
          ? null
          : Mention.configure({
              renderHTML({ node }) {
                return [
                  'span',
                  {
                    class: 'mention',
                    'data-mention-id': node.attrs.id,
                  },
                  `@${node.attrs.label}`,
                ];
              },
              suggestion,
            }),
      ],
      onUpdate: ({ editor }) => {
        const content = editor.getHTML();
        if (content !== this.value) {
          this.$emit('input', content);
        }
      },
    });
  },
  beforeDestroy() {
    // Destroy the editor and clean up any remaining Tippy instances
    if (this.editor) {
      this.editor.destroy();
    }
    if (this.popup) {
      this.popup[0].destroy();
    }
    if (this.component) {
      this.component.$destroy();
    }
  },
  methods: {
    clickPaletteColor(color) {
      const colorChange = this.colorSelected === color ? null : color;
      this.editor.chain().focus().setColor(colorChange).run();
      this.$root.$emit('bv::hide::popover', 'color-palette');
    },
  },
};
</script>

<style lang="scss">
.table-edit-buttons {
  background-color: rgba(61, 37, 20, 0.12);
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
}
.table-edit-buttons:hover {
  background-color: rgba(61, 37, 20, 0.32);
}

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid rgba(61, 37, 20, 0.12);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: rgba(61, 37, 20, 0.05);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(61, 37, 20, 0.08);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #800080;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  .mention {
    background-color: #dab1da;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: #800080;
    padding: 0.1rem 0.3rem;
  }

  font-size: 14px;
  border: 1px solid #e5eaee;
  border-radius: 8px;
  padding: 12px;
  line-height: 1.3;
}
p.is-editor-empty:first-child::before {
  color: #b5b5c3;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.toolbar-button {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toolbar-button.is-active {
  color: rgb(62, 118, 249);
  background-color: rgb(229, 240, 254);
}

.toolbar-button:not(.is-active):not(.disabled):hover {
  background-color: #e5eaee;
}
.toolbar-button.is-active:hover {
  background-color: rgb(210, 228, 251);
}

.toolbar {
  border: 1px solid #e5eaee;
  border-radius: 4px;
  background-color: #e5eaee20;
  padding: 3px;
  gap: 2px;
  display: flex;
  align-items: center;
}

.vertical-seperator {
  background-color: #bfc4c9;
  height: 16px;
  width: 1px;
  margin: 5px;
}

:deep(.dropdown) {
  width: 32px !important;
  height: 32px;
}

:deep(.b-popover) > :deep(.arrow) {
  visibility: hidden;
}

.color-palette > .popover-body {
  width: 160px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 12px;
  gap: 4px;
}

.palette-color:hover {
  opacity: 50%;
}
</style>
