<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="d-flex align-items-center" style="padding: 1rem 1rem">
      <ActionButton
        class="mr-3"
        v-b-toggle.comments-collapse
        title="Ausklappen"
        :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
        @click="toggleCollapse"
        v-if="isReiseterminDetailseite"
      />
      <h5 class="card-label mb-0">Kommentare</h5>
      <div class="card-toolbar ml-auto" v-if="showDepartmentButtons">
        <b-button
          v-for="(department, index) in departmentsOfCommentCreators"
          class="px-1 mx-1"
          :class="`border-${departmentButtonClass(department).border}`"
          :style="{
            'background-color':
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).color
                : '',
            'border-color': departmentButtonClass(department).color,
            color:
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).fontColor
                : '',
          }"
          :key="index"
          style="
            height: calc(1.5em + 1.3rem + 2px);
            width: calc(1.5em + 1.3rem + 2px);
            font-weight: bold;
            font-size: 0.8rem;
          "
          @click="changeDepartment(departmentButtonClass(department).label)"
        >
          {{ departmentButtonClass(department).label }}
        </b-button>
      </div>
    </div>
    <div style="padding: 0.25rem 1rem" class="mb-4">
      <b-overlay :show="isCommentsBusy">
        <div class="d-flex flex-column">
          <div
            class="mb-2"
            style="max-height: 500px; overflow-y: scroll"
            v-if="isCommented"
            id="commentsList"
          >
            <Comment
              v-for="comment in comments"
              :key="comment.id"
              :comment="comment"
              class="mb-2 comment-box"
              @comment-edit="editComment"
              @comment-delete="deleteComment"
              @comment-reply="clickOnReplyButton"
              @reactionMade="reactionMade"
              @delete-reaction="handleDeleteReaction"
              @change-reaction="handleChangeReaction"
              @save-reaction="handleSaveReaction"
              @toggle-comment-mark="onToggleCommentMark"
            />
          </div>
          <div v-if="comments && comments?.length === 0" class="font-weight-bold text-muted">
            Keine Kommentare vorhanden
          </div>
        </div>
      </b-overlay>
    </div>
    <div class="mt-auto" style="padding: 1rem 1rem" id="comments-collapse" v-if="isCollapseOpen">
      <CommentInputField
        @save-comment="saveComment"
        @save-edit-comment="saveEditComment"
        @cancel-edit="cancelEditComment"
        @cancel-reply="cancelReply"
        @save-reply-comment="saveReplyComment"
      />
    </div>
  </div>
</template>
<script>
import Comment from '@/components/comments/comment.vue';
import CommentInputField from '@/components/aufgaben/comment-input-field.vue';
import { mapState } from 'vuex';
import { departmentButtonClass } from '@/core/common/helpers/utils';
import ActionButton from '@/components/common/action-button.vue';

export default {
  components: {
    Comment,
    CommentInputField,
    ActionButton,
  },
  props: {
    comments: { type: Array, required: true, default: () => [] },
    showDepartmentButtons: { type: Boolean, default: false },
    selectedDepartment: { type: String },
    departmentsOfCommentCreators: { type: Array, default: () => [] },
  },
  data() {
    return {
      isCollapseOpen: true,
    };
  },
  mounted() {
    if (this.$route.name === 'reisetermine-view') this.isCollapseOpen = false;
  },
  computed: {
    ...mapState({
      isCommentsBusy: state => state.aufgaben.isCommentsBusy,
      users: state => state.users['users'],
    }),
    isCommented() {
      return this.comments?.length > 0;
    },
    isReiseterminDetailseite() {
      return this.$route.name === 'reisetermine-view' || this.$route.fullPath.includes('releasepruefung');
    },
  },
  methods: {
    departmentButtonClass,

    // Kommentar bearbeiten
    editComment(commentId, commentText) {
      this.$emit('comment-edit', commentId, commentText);
    },

    // Kommentar löschen
    deleteComment(commentId) {
      this.$emit('comment-delete', commentId);
    },

    // Auf "Antworten" Button klicken
    clickOnReplyButton(comment) {
      this.$emit('comment-reply', comment);
    },

    // Reaktion auf einen Kommentar
    reactionMade(reaction) {
      this.$emit('reaction-made', reaction);
    },

    // Neuen Kommentar speichern
    saveComment(commentData) {
      this.$emit('save-comment', commentData);
    },

    // Bearbeiteten Kommentar speichern
    saveEditComment(commentData) {
      this.$emit('save-edit-comment', commentData);
    },

    // Bearbeitung abbrechen
    cancelEditComment() {
      this.$emit('cancel-edit');
    },

    // Antwort abbrechen
    cancelReply() {
      this.$emit('cancel-reply');
    },

    // Antwort-Kommentar speichern
    saveReplyComment(replyData) {
      this.$emit('save-reply-comment', replyData);
    },

    handleDeleteReaction({ reactionCommentId }) {
      this.$emit('delete-reaction', { reactionCommentId });
    },

    handleChangeReaction({ oldReactionCommentId, replyCommentId, emoji }) {
      this.$emit('change-reaction', {
        oldReactionCommentId,
        replyCommentId,
        emoji,
      });
    },

    handleSaveReaction({ replyCommentId, emoji }) {
      this.$emit('save-reaction', { replyCommentId, emoji });
    },

    onToggleCommentMark(commentId) {
      this.$emit('toggle-comment-mark', { commentId });
    },
    changeDepartment(params) {
      this.$emit('changeDepartment', params);
    },
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
  },
};
</script>
