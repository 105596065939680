<template>
  <div class="p-4">
    <div v-for="reisetermin in reisetermine" :key="reisetermin.id" class="mb-4">
      <h6>{{ reisetermin.reiseterminkuerzel }}</h6>
      <ReiseterminViewCardFlugdaten :reiseterminObj="reisetermin"></ReiseterminViewCardFlugdaten>
    </div>
  </div>
</template>
<script>
import ReiseterminViewCardFlugdaten from '@/components/produkte/reisetermine/reisetermin-card-flugdata.vue';

export default {
  name: 'AufgabenFlugdetails',
  props: { reisetermine: { type: Array, required: true } },
  components: {
    ReiseterminViewCardFlugdaten,
  },
};
</script>
