<template>
  <div>
    <ag-charts-vue :options="options"></ag-charts-vue>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';
import { compareAsc } from 'date-fns';

export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: { datapoints: { type: Array }, showLegend: { type: Boolean, default: true } },
  data() {
    return {
      options: null,
    };
  },
  watch: {
    datapoints(newData) {
      const options = { ...this.options };
      options.data = [
        ...newData
          .map(datapoint => ({
            date: datapoint.abfragedatum,
            aufpreis: datapoint.aufpreis ? datapoint.aufpreis : 0,
            status: datapoint.status,
          }))
          .sort((a, b) =>
            compareAsc(
              new Date(a.date.substring(3, 5) + '/' + a.date.substring(0, 2) + '/' + a.date.substring(6, 10)),
              new Date(b.date.substring(3, 5) + '/' + b.date.substring(0, 2) + '/' + b.date.substring(6, 10))
            )
          ),
      ];
      this.options = options;
    },
  },
  computed: {},
  created() {
    this.options = {
      axes: [
        {
          type: 'category',
          position: 'bottom',
          paddingInner: 0.1,
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: this.showLegend ? `Aufpreis` : null,
          },
          min: 0,

          label: {
            formatter: params => params.value + '€',
          },
        },
      ],
      autoSize: true,
      series: [
        {
          data: [
            ...this.datapoints
              .map(datapoint => ({
                date: datapoint.abfragedatum,
                aufpreis: datapoint.aufpreis ? datapoint.aufpreis : 0,
                status: datapoint.status,
              }))
              .sort((a, b) =>
                compareAsc(
                  new Date(
                    a.date.substring(3, 5) + '/' + a.date.substring(0, 2) + '/' + a.date.substring(6, 10)
                  ),
                  new Date(
                    b.date.substring(3, 5) + '/' + b.date.substring(0, 2) + '/' + b.date.substring(6, 10)
                  )
                )
              ),
          ],
          tooltip: { renderer: this.renderer },
          xKey: 'date',
          yKey: 'aufpreis',
          //   yName: 'Lounge Temperature',
          stroke: '#000',
          marker: {
            formatter: this.formatter,
          },
        },
      ],
    };
  },
  mounted() {},
  methods: {
    renderer(params) {
      return (
        '<div>' +
        '<div style="padding: 8px;">Aufpreis: ' +
        (params.datum.status !== 'Verfuegbar' ? '-' : params.datum.aufpreis + '€') +
        '</div>' +
        '</div>'
      );
    },
    formatter(params) {
      const color =
        params.datum.status === 'Verfuegbar'
          ? '#1BC5BD'
          : params.datum.status === 'NichtVerfuegbar'
          ? '#F64E60'
          : '#FFA800';
      return { fill: color, size: 15 };
    },
  },
};
</script>
