var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"padding":"1rem 1rem"}},[(_vm.isReiseterminDetailseite)?_c('ActionButton',{directives:[{name:"b-toggle",rawName:"v-b-toggle.comments-collapse",modifiers:{"comments-collapse":true}}],staticClass:"mr-3",attrs:{"title":"Ausklappen","iconClass":_vm.isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'},on:{"click":_vm.toggleCollapse}}):_vm._e(),_c('h5',{staticClass:"card-label mb-0"},[_vm._v("Kommentare")]),(_vm.showDepartmentButtons)?_c('div',{staticClass:"card-toolbar ml-auto"},_vm._l((_vm.departmentsOfCommentCreators),function(department,index){return _c('b-button',{key:index,staticClass:"px-1 mx-1",class:`border-${_vm.departmentButtonClass(department).border}`,staticStyle:{"height":"calc(1.5em + 1.3rem + 2px)","width":"calc(1.5em + 1.3rem + 2px)","font-weight":"bold","font-size":"0.8rem"},style:({
          'background-color':
            _vm.selectedDepartment === _vm.departmentButtonClass(department).label
              ? _vm.departmentButtonClass(department).color
              : '',
          'border-color': _vm.departmentButtonClass(department).color,
          color:
            _vm.selectedDepartment === _vm.departmentButtonClass(department).label
              ? _vm.departmentButtonClass(department).fontColor
              : '',
        }),on:{"click":function($event){_vm.changeDepartment(_vm.departmentButtonClass(department).label)}}},[_vm._v(" "+_vm._s(_vm.departmentButtonClass(department).label)+" ")])}),1):_vm._e()],1),_c('div',{staticClass:"mb-4",staticStyle:{"padding":"0.25rem 1rem"}},[_c('b-overlay',{attrs:{"show":_vm.isCommentsBusy}},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.isCommented)?_c('div',{staticClass:"mb-2",staticStyle:{"max-height":"500px","overflow-y":"scroll"},attrs:{"id":"commentsList"}},_vm._l((_vm.comments),function(comment){return _c('Comment',{key:comment.id,staticClass:"mb-2 comment-box",attrs:{"comment":comment},on:{"comment-edit":_vm.editComment,"comment-delete":_vm.deleteComment,"comment-reply":_vm.clickOnReplyButton,"reactionMade":_vm.reactionMade,"delete-reaction":_vm.handleDeleteReaction,"change-reaction":_vm.handleChangeReaction,"save-reaction":_vm.handleSaveReaction,"toggle-comment-mark":_vm.onToggleCommentMark}})}),1):_vm._e(),(_vm.comments && _vm.comments?.length === 0)?_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" Keine Kommentare vorhanden ")]):_vm._e()])])],1),(_vm.isCollapseOpen)?_c('div',{staticClass:"mt-auto",staticStyle:{"padding":"1rem 1rem"},attrs:{"id":"comments-collapse"}},[_c('CommentInputField',{on:{"save-comment":_vm.saveComment,"save-edit-comment":_vm.saveEditComment,"cancel-edit":_vm.cancelEditComment,"cancel-reply":_vm.cancelReply,"save-reply-comment":_vm.saveReplyComment}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }