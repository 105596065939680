<template>
  <span class="ml-2">
    <i
      class="fas fa-check text-success icon-sm"
      v-if="passdatenAttribute.required && passdatenAttribute.provided"
    >
    </i>
    <i
      class="fas fa-asterisk text-danger icon-sm"
      v-if="passdatenAttribute.required && !passdatenAttribute.provided"
    >
    </i>
  </span>
</template>
<script>
export default {
  props: {
    passdatenAttribute: { type: Object, required: true },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
