<template>
  <div v-if="reiseterminObj">
    <div class="gutter-b">
      <b-table
        style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
        head-variant="light"
        responsive
        :fields="fieldsTableOne"
        :items="[reiseterminObj]"
      >
        <template v-slot:cell(ziellandZielflughaefen)="row">
          {{ row.item.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
        </template>
        <template v-slot:cell(ziellandAbflughaefen)="row">
          {{ row.item.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
        </template>
        <template v-slot:cell(metadata.hotelstartVorLandung)="row">
          <CustomIconChecked :value="row.item.metadata.hotelstartVorLandung" />
        </template>
        <template v-slot:cell(metadata.rueckflugNachHotelende)="row">
          <CustomIconChecked :value="row.item.metadata.rueckflugNachHotelende" />
        </template>
      </b-table>
    </div>

    <div class="gutter-b">
      <b-table
        style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
        responsive
        :fields="fieldsHinUndRueckflugTable"
        :items="[reiseterminObj]"
      >
        <template #thead-top="data">
          <b-th variant="primary" colspan="3"></b-th>
          <b-th variant="primary" colspan="3" class="border-right">Hinflug</b-th>
          <b-th variant="danger" colspan="3"></b-th>
          <b-th variant="danger" colspan="3">Rückflug</b-th>
        </template>
      </b-table>
    </div>

    <div v-if="this.reiseterminObj.metadata.inlandsfluege?.length > 0">
      <div>
        <b-table
          style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-bottom: 20px"
          responsive
          :fields="fieldsInlandsfluegeTable"
          :items="reiseterminObj.metadata.inlandsfluege"
          sort-by="tag"
        >
          <template #thead-top="data">
            <b-th variant="warning" colspan="8">Inlandsflüge</b-th>
          </template>
          <template v-slot:cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(datum)="row">
            {{ getDateOfIlandsflug(reiseterminObj, row.item) }}
          </template>
          <template v-slot:cell(IataCode)="row">
            <span v-if="row.item.abflughafenIataCode && row.item.zielflughafenIataCode">
              {{ row.item.abflughafenIataCode }} / {{ row.item.zielflughafenIataCode }}
            </span>
            <span v-else>-</span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/common/action-button.vue';
import { add, set, format } from 'date-fns';
import { formatDateWithoutHours, fernRegions, euRegions } from '@/core/common/helpers/utils.js';
import CustomIconChecked from '@/components/common/custom-icon-checked.vue';

export default {
  props: {
    reiseterminObj: { type: Object },
  },
  components: {
    ActionButton,
    CustomIconChecked,
  },
  data() {
    return {
      isCollapseOpen: true,
      restriktionenHinRollover: null,
      restriktionenRueckRollover: null,
      fieldsTableOne: [
        {
          tdClass: 'align-middle',
          key: 'metadata.skrFlugangebot',
          label: 'SKR Flugangebot',
          formatter: value => (value ? value + ' €' : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandZielflughaefen',
          label: 'ZL Zielflughafen',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandAbflughaefen',
          label: 'ZL Abflughafen',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'flugeinkauf.einkaufsart',
          label: 'Einkaufsart',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightOutbound',
          label: 'OB',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightInbound',
          label: 'IB',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'flugeinkauf.gruppePnr',
          label: 'GRP PNR',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.hotelstartVorLandung',
          label: 'Hotelstart v. Landung',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.rueckflugNachHotelende',
          label: 'Rückflug n. Hotelende',
        },
      ],
      fieldsHinUndRueckflugTable: [
        {
          tdClass: 'align-middle',
          key: 'flugeinkauf.flugnummerGruppeHin',
          label: 'Flugnr. GRP',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'abreisedatum',
          label: 'Abreise',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.fruehesteAnkunft',
          label: 'Früh. Ankunft',
          formatter: value => (value ? this.fruehesteAnkunftDateFormatted : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.spaetesteAnkunft',
          label: 'Spät. Ankunft',
          formatter: value => (value ? this.spaetesteAnkunftDateFormatted : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferszenarioHin',
          label: 'Transferszenario',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          tdClass: 'align-middle border-right',
          thClass: 'align-middle border-right',
          key: 'metadata.transferdauerHin',
          label: 'Transferdauer',
          formatter: value => (value ? `${value} min` : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'flugeinkauf.flugnummerGruppeRueck',
          label: 'Flugnr. GRP',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.fruehesterAbflug',
          label: 'Früh. Abflug',
          formatter: value => (value ? this.fruehesterAbflugDateFormatted : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.spaetesterAbflug',
          label: 'Spät. Abflug',
          formatter: value => (value ? this.spaetesterAbflugDateFormatted : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferszenarioRueck',
          label: 'Transferszenario',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferdauerRueck',
          label: 'Transferdauer',
          formatter: value => (value ? `${value} min` : '-'),
        },
      ],
      fieldsInlandsfluegeTable: [
        {
          thClass: 'd-none',
          tdClass: 'd-none',
          key: 'tag',
        },
        { tdClass: 'align-middle', key: 'index', label: '#' },
        {
          tdClass: 'align-middle',
          key: 'datum',
          label: 'Datum',
          formatter: value => (value ? this.getDateOfIlandsflug(this.reisetermin, value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'einkauf',
          label: 'Einkauf',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'IataCode',
          label: 'Abflughafen / Zielflughafen',
        },
        {
          tdClass: 'align-middle',
          key: 'fruehesterAbflug',
          label: 'Früh. Abflug',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'spaetesteAnkunft',
          label: 'Spät. Ankunft',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'minimaleUmsteigezeit',
          label: 'Minimale Umsteigezeit',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'flugnummer',
          label: 'Flugnummer',
          formatter: value => value ?? '-',
        },
      ],
    };
  },
  computed: {
    fruehesteAnkunftDate() {
      if (!this.reiseterminObj || !this.reiseterminObj.metadata?.fruehesteAnkunft) return null;

      const { startdatum } = this.reiseterminObj;
      const { fruehesteAnkunft } = this.reiseterminObj.metadata;

      const date = new Date(startdatum);
      const time = { hours: fruehesteAnkunft.substring(0, 2), minutes: fruehesteAnkunft.substring(3, 5) };

      return set(date, time);
    },
    fruehesteAnkunftDateFormatted() {
      if (!this.fruehesteAnkunftDate) return '-';

      return format(this.fruehesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    fruehesterAbflugDate() {
      if (!this.reiseterminObj || !this.reiseterminObj.metadata?.fruehesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.reiseterminObj;
      const { fruehesterAbflug } = this.reiseterminObj.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: fruehesterAbflug.substring(0, 2), minutes: fruehesterAbflug.substring(3, 5) };

      return set(date, time);
    },
    fruehesterAbflugDateFormatted() {
      if (!this.fruehesterAbflugDate) return '-';

      return format(this.fruehesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesteAnkunftDate() {
      if (!this.reiseterminObj || !this.reiseterminObj.metadata?.spaetesteAnkunft) return null;
      const { startdatum } = this.reiseterminObj;
      const { spaetesteAnkunft, restriktionenHinRollover } = this.reiseterminObj.metadata;

      const date = new Date(startdatum);
      const time = { hours: spaetesteAnkunft.substring(0, 2), minutes: spaetesteAnkunft.substring(3, 5) };

      return add(set(date, time), { days: restriktionenHinRollover ?? 0 });
    },
    spaetesteAnkunftDateFormatted() {
      if (!this.spaetesteAnkunftDate) return '-';

      return format(this.spaetesteAnkunftDate, 'dd.MM.yyyy HH:mm');
    },
    spaetesterAbflugDate() {
      if (!this.reiseterminObj || !this.reiseterminObj.metadata?.spaetesterAbflug) return null;
      const { hotelendUndRueckflugdatum } = this.reiseterminObj;
      const { spaetesterAbflug, restriktionenRueckRollover } = this.reiseterminObj.metadata;

      const date = new Date(hotelendUndRueckflugdatum);
      const time = { hours: spaetesterAbflug.substring(0, 2), minutes: spaetesterAbflug.substring(3, 5) };

      return add(set(date, time), { days: restriktionenRueckRollover ?? 0 });
    },
    spaetesterAbflugDateFormatted() {
      if (!this.spaetesterAbflugDate) return '-';

      return format(this.spaetesterAbflugDate, 'dd.MM.yyyy HH:mm');
    },
  },

  methods: {
    getFormattedTransferkosten(region, transferkosten) {
      const multiplier = this.isFernRegion(region) ? 1.2 : 1.5;

      if (isNaN(transferkosten)) return '-';
      return this.formatCurrency(transferkosten * multiplier);
    },
    isFernRegion(region) {
      return fernRegions.includes(region);
    },
    isEuRegion(region) {
      return euRegions.includes(region);
    },
    formatCurrency(value) {
      return String(value.toFixed(2)).replace('.', ',') + ' €';
    },
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    getDateOfIlandsflug(reisetermin, inlandsflug) {
      const dateOfInlandsfluge = add(new Date(this.reiseterminObj.abreisedatum), {
        days: +inlandsflug.tag - 1,
      });

      return format(dateOfInlandsfluge, 'dd.MM.yyyy');
    },
  },
};
</script>

<style>
.border-right {
  border-right: 2px solid rgb(0, 0, 0, 0.1);
}
</style>
