var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{staticStyle:{"border":"1px solid rgb(0, 0, 0, 0.1)","border-radius":"5px","margin-top":"12px"},attrs:{"items":_vm.fvcData,"fields":_vm.flugverfuegbarkeitenFields,"responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"5px"}},[_c('b-button',{attrs:{"size":"sm","href":'/#/reisetermine/fvc/overview/?reise=' + row.item.reisetermin.reiseterminkuerzel.substring(0, 6),"target":'_blank'}},[_vm._v(" FVC ")]),_c('b-button',{attrs:{"size":"sm","href":'/#/reisetermine/' + row.item.reisetermin.id,"target":'_blank'}},[_vm._v(" RT ")]),_c('b-button',{attrs:{"size":"sm","href":'/#/reisen/' + row.item.reisetermin.reise.reisekuerzel + '/reisestammdaten',"target":'_blank'}},[_vm._v(" R ")])],1)]}},{key:"cell(reisetermin.reiseterminkuerzel)",fn:function(data){return [_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(status)",fn:function(row){return [_c('h6',{staticClass:"m-0"},[_c('span',{class:`badge badge-${
            row.item.status === 'Verfuegbar'
              ? 'success'
              : row.item.status === 'NichtVerfuegbar'
              ? 'danger'
              : 'warning'
          }`},[_vm._v(" "+_vm._s(row.item.status === 'Verfuegbar' ? 'J' : row.item.status === 'NichtVerfuegbar' ? 'N' : 'RQ')+" ")])])]}},{key:"cell(reisezeitraum)",fn:function(row){return [_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(_vm.formatDateWithoutHours(row.item.reisetermin.abreisedatum))+" - "+_vm._s(_vm.formatDateWithoutHours(row.item.reisetermin.enddatum))+" ")])]}},{key:"cell(pax)",fn:function(row){return [_c('PaxWidget',{staticClass:"mb-2",attrs:{"paxObject":row.item.reisetermin.pax,"kalkPax":row.item.reisetermin.metadata.kalkPax,"erwartetePax":row.item.reisetermin.metadata.erwartetePax,"durchfuehrbareMinPax":row.item.reisetermin.metadata.durchfuehrbareMinPax}})]}},{key:"cell(ziellandAbUndZielflughafen)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.reisetermin.metadata.ziellandZielflughaefen.map(fh => fh.flughafenIataCode).join(', '))+" / "+_vm._s(row.item.reisetermin.metadata.ziellandAbflughaefen.map(fh => fh.flughafenIataCode).join(', '))+" ")]}},{key:"cell(reisetermin.abgesagt)",fn:function(row){return [_c('CustomIconChecked',{attrs:{"value":row.item.reisetermin.abgesagt}})]}},{key:"cell(reisetermin.garantiert)",fn:function(row){return [_c('CustomIconChecked',{attrs:{"value":row.item.reisetermin.garantiert}})]}},{key:"cell(reisetermin.stopSale)",fn:function(row){return [_c('CustomIconExclamation',{attrs:{"value":row.item.reisetermin.stopSale}})]}},{key:"cell(aufgaben)",fn:function(row){return [_c('b-badge',{attrs:{"id":'tooltip-target-flugverfuegbarkeit' + row.item.id}},[_vm._v(" "+_vm._s(row.item.aufgaben?.length)+" ")]),(row.item.aufgaben?.length > 1)?_c('b-tooltip',{attrs:{"target":'tooltip-target-flugverfuegbarkeit' + row.item.id,"triggers":"hover","custom-class":"custom-tooltip"}},[_c('AufgabenWidget',{staticClass:"mt-3",attrs:{"openAufgabenId":_vm.openAufgabenId,"ansicht":'aufgabe-details',"aufgaben":row.item.aufgaben || []}})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }