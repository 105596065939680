<template>
  <Comments
    :comments="comments"
    :showDepartmentButtons="showDepartmentButtons"
    :departmentsOfCommentCreators="departmentsOfCommentCreators"
    :selectedDepartment="selectedDepartment"
    @comment-edit="handleCommentEdit"
    @comment-delete="handleCommentDelete"
    @comment-reply="handleCommentReply"
    @save-comment="handleSaveComment"
    @save-edit-comment="handleSaveEditComment"
    @cancel-edit="handleCancelEdit"
    @cancel-reply="handleCancelReply"
    @save-reply-comment="handleSaveReplyComment"
    @delete-reaction="handleDeleteReaction"
    @change-reaction="handleChangeReaction"
    @save-reaction="handleSaveReaction"
    @toggle-comment-mark="onToggleCommentMark"
    @changeDepartment="changeDepartment"
    @toggleCollapse="toggleCollapse"
  />
</template>
<script>
import Comments from '@/components/comments/comments.vue';
import {
  SAVE_REPLY_COMMENT,
  SET_MODE,
  SET_COMMENT_TEXT,
  SET_EDIT_COMMENT_ID,
  SET_REPLY_COMMENT_ID,
  SAVE_EDIT_COMMENT,
  DELETE_COMMENT,
  SAVE_COMMENT,
  DELETE_REACTION,
  SAVE_REACTION,
  CHANGE_REACTION,
  TOGGLE_MARK_COMMENT,
} from '@/core/comments/comments.module';

export default {
  components: { Comments },
  props: {
    comments: { type: Array },
    reiseterminId: { type: Number },
    showDepartmentButtons: { type: Boolean, default: false },
    departmentsOfCommentCreators: { type: Array, default: () => [] },
    selectedDepartment: { type: String },
  },
  methods: {
    handleSaveComment() {
      this.$store
        .dispatch(SAVE_COMMENT, { isAufgabenComments: false, reiseterminId: this.reiseterminId })
        .then(res => {
          console.log(res);
          this.toast('Kommentar wurde gespeichert.');
          this.$emit('updateComments', res);
        });
      this.$store.commit(SET_COMMENT_TEXT, '');
    },
    async handleCommentDelete(commentId) {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du den Kommentar wirklich löschen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });
      if (confirmed) {
        this.$store
          .dispatch(DELETE_COMMENT, {
            commentId,
            isAufgabenComments: false,
            reiseterminId: this.reiseterminId,
          })
          .then(res => {
            this.$emit('updateComments', res);
            this.toast('Kommentar wurde gelöscht.');
          });
        this.$store.commit(SET_COMMENT_TEXT, '');
        this.$store.commit(SET_MODE, '');
        this.$store.commit(SET_EDIT_COMMENT_ID, null);
        this.$store.commit(SET_REPLY_COMMENT_ID, null);
      }
    },
    handleSaveReplyComment() {
      this.$store
        .dispatch(SAVE_REPLY_COMMENT, { isAufgabenComments: false, reiseterminId: this.reiseterminId })
        .then(res => {
          this.$emit('updateComments', res);
          this.toast('Antwort auf Kommentar wurde erstellt.');
        });
    },
    handleCommentReply(commentId) {
      this.$store.commit(SET_MODE, 'reply');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_EDIT_COMMENT_ID, null);
      this.$store.commit(SET_REPLY_COMMENT_ID, commentId);
    },
    handleCancelReply() {
      this.$store.commit(SET_MODE, '');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_REPLY_COMMENT_ID, null);
    },
    handleCancelEdit() {
      this.$store.commit(SET_MODE, '');
      this.$store.commit(SET_COMMENT_TEXT, '');
      this.$store.commit(SET_EDIT_COMMENT_ID, null);
    },
    handleSaveEditComment(commentText) {
      this.$store
        .dispatch(SAVE_EDIT_COMMENT, {
          commentText,
          isAufgabenComments: false,
          reiseterminId: this.reiseterminId,
        })
        .then(res => {
          this.$emit('updateComments', res);
          this.toast('Kommentar wurde geändert.');
        });
    },
    handleCommentEdit(commentId, commentText) {
      this.$store.commit(SET_COMMENT_TEXT, commentText);
      this.$store.commit(SET_MODE, 'edit');
      this.$store.commit(SET_REPLY_COMMENT_ID, null);
      this.$store.commit(SET_EDIT_COMMENT_ID, commentId);
    },
    handleDeleteReaction({ reactionCommentId }) {
      this.$store
        .dispatch(DELETE_REACTION, {
          commentId: reactionCommentId,
          isAufgabenComments: false,
          reiseterminId: this.reiseterminId,
        })
        .then(res => this.$emit('updateComments', res));
    },

    handleChangeReaction({ oldReactionCommentId, replyCommentId, emoji }) {
      this.$store
        .dispatch(CHANGE_REACTION, {
          commentId: oldReactionCommentId,
          isAufgabenComments: false,
          reiseterminId: this.reiseterminId,
          text: emoji,
          replyCommentId,
        })
        .then(res => this.$emit('updateComments', res));
    },

    handleSaveReaction({ replyCommentId, emoji }) {
      this.$store
        .dispatch(SAVE_REACTION, {
          text: emoji,
          replyCommentId,
          isAufgabenComments: false,
          reiseterminId: this.reiseterminId,
        })
        .then(res => this.$emit('updateComments', res));
    },
    onToggleCommentMark({ commentId }) {
      this.$store
        .dispatch(TOGGLE_MARK_COMMENT, {
          commentId,
          isAufgabenComments: false,
          reiseterminId: this.reiseterminId,
        })
        .then(res => this.$emit('updateComments', res));
    },
    changeDepartment(params) {
      this.$emit('changeDepartment', params);
    },
    toggleCollapse(params) {
      this.$emit('toggleCollapse', params);
    },
  },
};
</script>
