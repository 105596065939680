<template>
  <div>
    <ag-charts-vue :options="options"></ag-charts-vue>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue';

export default {
  components: {
    'ag-charts-vue': AgChartsVue,
  },
  props: { datapoints: { type: Array }, showLegend: { type: Boolean, default: true } },
  data() {
    return {
      options: null,
    };
  },
  watch: {
    datapoints(newData) {
      const options = { ...this.options };
      options.data = [...newData];
      this.options = options;
    },
  },
  computed: {},
  created() {
    this.options = {
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            formatter: function (params) {
              return params.value.substring(0, 6);
            },
          },
        },
        {
          type: 'number',
          position: 'left',
          min: 0,
        },
      ],
      autoSize: true,
      series: [
        {
          data: [...this.datapoints],
          xKey: 'date',
          yKey: 'value',
          stroke: '#000',
          marker: {
            shape: 'square',
            fill: 'black',
            stroke: 'black',
          },
          tooltip: {
            renderer: function ({ datum, xKey, yKey }) {
              return {
                content: 'Einheiten frei: ' + datum[yKey].toFixed(0),
              };
            },
          },
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
