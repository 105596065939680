<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="reisenData"
      :fields="reisenFields"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(prioritaet)="row">
        <b-badge>{{ getValueByPriority(row.item.prioritaet) }}</b-badge>
      </template>
      <template v-slot:cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-reise' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-reise' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
    </b-table>
  </div>
</template>
<script>
import configPriority from '@/core/produkte/reisen/priority.config.json';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';

export default {
  components: { AufgabenWidget },
  props: { reisenData: { type: Array, required: true }, openAufgabenId: { type: Number, default: null } },
  data() {
    return {
      reisenFields: [
        {
          tdClass: 'align-middle',
          key: 'reisekuerzel',
          label: 'Reisekürzel',
        },
        {
          tdClass: 'align-middle',
          key: 'titel',
          label: 'Reisetitel',
        },
        {
          tdClass: 'align-middle',
          key: 'country',
          label: 'Land',
        },
        {
          tdClass: 'align-middle',
          key: 'region',
          label: 'Region',
        },
        {
          tdClass: 'align-middle',
          key: 'prioritaet',
          label: 'Prio.',
        },
        { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
      ],
    };
  },
  methods: {
    getVariantByPriority(priority) {
      return configPriority[priority].color;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    rowClicked(item) {
      window.open('/#/reisen/' + item.reisekuerzel + '/edit', '_blank');
    },
  },
};
</script>
