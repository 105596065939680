<template>
  <b-button
    v-if="!isExternalLink"
    @click="$emit('click', $event)"
    :to="link"
    :target="target"
    :variant="variant"
    :aria-label="title"
    :title="title"
    :class="extraClass + (iconClass ? ' btn-icon' : '')"
    class="btn btn-light-primary btn-xs p-0 ml-1 mb-1"
    :disabled="disabled"
  >
    <i v-if="!isBootstrapIcon && iconClass" :class="iconClass" />
    <b-icon v-else-if="bootstrapIconName" :icon="bootstrapIconName" />
    <div v-else class="btn-text">
      <slot />
    </div>
  </b-button>
  <a v-else :target="target" :href="link">
    <b-button
      @click="$emit('click', $event)"
      :variant="variant"
      :aria-label="title"
      :title="title"
      :class="extraClass + (iconClass ? ' btn-icon' : '')"
      class="btn btn-light-primary btn-xs p-0 ml-1 mb-1"
      :disabled="disabled"
    >
      <i v-if="!isBootstrapIcon && iconClass" :class="iconClass" />
      <b-icon v-else :icon="bootstrapIconName" />
      <i class="bi-check b-icon bi" />
      <slot />
    </b-button>
  </a>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    iconClass: {
      type: String,
      default: null,
    },
    extraClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
    },
    link: {
      type: [Object, String],
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },

  computed: {
    isExternalLink() {
      return typeof this.link === 'string' && this.link.startsWith('http');
    },
    isBootstrapIcon() {
      return typeof this.iconClass === 'string' && this.iconClass.startsWith('b-icon');
    },
    bootstrapIconName() {
      return this.isBootstrapIcon && this.iconClass.replace('b-icon-', '');
    },
  },
};
</script>

<style scoped lang="scss">
.btn > .btn-text {
  padding: 1px 0.5rem;
}
</style>
