<template>
  <div>
    <b-modal size="md" id="aufgabeErgebnisFesthaltenModal" title="Bitte halte das Ergebnis der Aufgabe fest">
      <div class="d-flex">
        <b-form-group label="Ergebnis" style="width: 95%">
          <b-form-select v-model="ergebnis" :options="kategorien" text-field="text">
          </b-form-select> </b-form-group
        ><i
          v-if="ergebnis.length > 0"
          class="fas fa-times text-danger align-self-center ml-3"
          @click="ergebnis = []"
          style="cursor: pointer"
        ></i>
      </div>
      <b-form-group label="Beschreibung" style="width: 95%">
        <b-form-input v-model="ergebnisBeschreibung"></b-form-input>
      </b-form-group>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button
          class="mr-4"
          :disabled="disableSichernButton"
          :variant="disableSichernButton ? 'secondary' : 'success'"
          @click="handleSubmit(hide)"
          size="sm"
          >Speichern</b-button
        >
        <b-button @click="hide" size="sm">Abbrechen</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import apiService from '@/core/common/services/api.service';
import { ergebnisOptions } from '@/core/common/helpers/utils';
import { mapGetters } from 'vuex';

export default {
  props: { aufgabeId: { type: Number, required: true }, aufgabenKategorie: { type: String, required: true } },
  computed: {
    ...mapGetters(['getErgebnisKategorienOptions']),
    kategorien() {
      if (this.aufgabenKategorie?.length > 0) {
        const aufgabenKategorieErgebnisOptions = this.getErgebnisKategorienOptions(this.aufgabenKategorie);
        return ergebnisOptions.filter(ergebnis => aufgabenKategorieErgebnisOptions.includes(ergebnis.value));
      } else return [];
    },
    disableSichernButton() {
      return this.ergebnis.length === 0;
    },
  },
  data() {
    return {
      ergebnis: [],
      ergebnisBeschreibung: '',
      ergebnisOptions,
    };
  },

  methods: {
    handleSubmit(hide) {
      apiService
        .put('/aufgaben/setErgebnis', {
          aufgabeId: this.aufgabeId,
          ergebnis: this.ergebnis,
          ergebnisBeschreibung: this.ergebnisBeschreibung.length === 0 ? null : this.ergebnisBeschreibung,
        })
        .then(() => {
          hide();
          this.$emit('ergebnisGespeichert');
        });
    },
  },
};
</script>
<style scoped>
:deep(.modal-footer) {
  padding: 5px;
}
</style>
