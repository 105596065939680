<template>
  <div>
    <AufgabenFlugdetails v-if="showFlugdetails" :reisetermine="reisetermine" />
  </div>
</template>

<script>
import AufgabenFlugdetails from './aufgaben-flugdetails.vue';

export default {
  name: 'AufgabenZusaetzlicheInfos',
  props: {
    showFlugdetails: { type: Boolean },
    reisetermine: { type: Array },
  },
  components: {
    AufgabenFlugdetails,
  },
};
</script>
