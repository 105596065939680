<template>
  <div
    class="d-flex flex-column py-4"
    style="gap: 24px; max-height: 400px; overflow-y: scroll; font-size: 1.05rem"
  >
    <div v-if="formattedData?.length === 0" class="p-4">
      <h6>Der Verlauf wird erst ab dem 05.09. gespeichert. Seitdem gibt es keine Änderungen!</h6>
    </div>
    <div v-for="(change, i) in formattedData" :key="change.key + ' ' + change.date" class="">
      <div class="d-flex">
        <span>
          {{ change.nameAenderer }} hat
          <b>{{ change.formattedKey }}</b>
          geändert.
        </span>
        <div
          style="color: rgba(0, 0, 0, 0.5); cursor: pointer"
          class="ml-6"
          :id="'date-change' + uniqueId + '-' + change.date + '-' + i"
        >
          {{ change.formattedDate }}
        </div>
      </div>
      <div class="d-flex p-2 align-items-center" style="gap: 12px">
        <span
          class="d-flex align-items-center"
          :id="'tooltip-beschreibung-old' + uniqueId + '-' + change.date"
          v-html="change.oldValueFormatted"
          :style="change.key === 'Beschreibung' ? 'max-width: 300px;cursor: pointer' : ''"
        ></span>
        <span style="font-size: 20px">&rarr;</span>
        <span
          :id="'tooltip-beschreibung-new' + uniqueId + '-' + change.date"
          v-html="change.newValueFormatted"
          :style="change.key === 'Beschreibung' ? 'max-width: 300px;cursor: pointer' : ''"
        ></span>

        <b-tooltip
          :target="'tooltip-beschreibung-old' + uniqueId + '-' + change.date"
          triggers="hover"
          v-if="change.key === 'Beschreibung'"
        >
          <span v-html="change.oldValue"></span>
        </b-tooltip>
        <b-tooltip
          :target="'tooltip-beschreibung-new' + uniqueId + '-' + change.date"
          triggers="hover"
          v-if="change.key === 'Beschreibung'"
        >
          <span v-html="change.newValue"></span>
        </b-tooltip>

        <b-tooltip
          :target="'date-change' + uniqueId + '-' + change.date + '-' + i"
          triggers="hover"
          placement="right"
        >
          <span style="font-size: 1rem">{{ formatDate(change.date) }}</span>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { format, formatDistanceToNow, isAfter } from 'date-fns';
import de from 'date-fns/locale/de';
import { statusFormatterHtml, prioritaetFormatterHtml, ergebnisOptions } from '@/core/common/helpers/utils';

export default {
  props: {
    data: { type: Object },
  },
  data() {
    return {
      ignoredFields: ['LastModifiedUserId', 'StatusErledigtDatum', 'StatusInArbeitDatum'],
      formatKeys: {
        Status: 'den Status',
        Prioritaet: 'die Priorität',
        Team: 'das Team',
        Bearbeiter: 'die zugewiesene Person',
        Beschreibung: 'die Beschreibung',
        Ergebnis: 'das Ergebnis',
        Faelligkeitsdatum: 'das Fälligkeitsdatum',
      },
      statusFormatterHtml,
      prioritaetFormatterHtml,
      ergebnisOptions,
      uniqueId: Math.random().toString(36).substring(2, 9),
    };
  },
  computed: {
    ...mapState({
      users: state => state.users['users'],
    }),
    formattedData() {
      return Object.entries(this.data)
        .reduce((acc, curr) => {
          const changeDate = curr[0];
          const { changes, lastModifiedUserId } = curr[1];

          const teamBearbeiterChangesWithNull = changes.filter(
            change =>
              ['Team', 'Bearbeiter'].includes(change.key) &&
              (change.newValue == null || change.oldValue == null)
          );

          if (teamBearbeiterChangesWithNull.length === 2) {
            const newValueObject = teamBearbeiterChangesWithNull.find(
              change => change.newValue != null && ['Team', 'Bearbeiter'].includes(change.key)
            );
            const oldValueObject = teamBearbeiterChangesWithNull.find(
              change => change.oldValue != null && ['Team', 'Bearbeiter'].includes(change.key)
            );
            const key = 'die zugewiesene Person/Team';
            const aendererObject = this.users.find(user => user.id === lastModifiedUserId);
            const nameAenderer = aendererObject
              ? aendererObject.givenName + ' ' + aendererObject.surname
              : '-';
            const newValueFormatted = this.getValueFormatted(newValueObject.newValue, newValueObject.key);
            const oldValueFormatted = this.getValueFormatted(oldValueObject.oldValue, oldValueObject.key);
            const formattedDate = this.getDateDistanceToNow(changeDate);
            acc.push({
              oldValue: oldValueObject.oldValue,
              oldValueFormatted,
              newValue: newValueObject.newValue,
              newValueFormatted,
              nameAenderer,
              date: changeDate,
              formattedDate,
              key,
              formattedKey: key,
            });
          }

          for (const change of changes) {
            if (
              this.ignoredFields.includes(change.key) ||
              change.newValue == null ||
              change.oldValue == null
            ) {
              continue;
            }

            const { newValue, oldValue, key } = change;
            const aendererObject = this.users.find(user => user.id === lastModifiedUserId);
            const nameAenderer = aendererObject
              ? aendererObject.givenName + ' ' + aendererObject.surname
              : '-';
            const formattedDate = this.getDateDistanceToNow(changeDate);

            const newValueFormatted = this.getValueFormatted(newValue, key);
            const oldValueFormatted = this.getValueFormatted(oldValue, key);
            acc.push({
              oldValue,
              oldValueFormatted,
              newValue,
              newValueFormatted,
              nameAenderer,
              date: changeDate,
              formattedDate,
              key,
              formattedKey: this.formatKeys[key],
            });
          }
          return acc;
        }, [])
        .sort((a, b) => (isAfter(new Date(b), new Date(a)) ? 1 : -1));
    },
  },
  methods: {
    getDateDistanceToNow(dateString) {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true, locale: de });
    },
    formatDate(value) {
      return format(new Date(value), 'dd.MM.yyyy  HH:mm') + ' Uhr';
    },
    getValueFormatted(value, key) {
      if (key === 'Status') {
        return this.statusFormatterHtml[value];
      }

      if (key === 'Prioritaet') {
        return this.prioritaetFormatterHtml[value];
      }

      if (key === 'Ergebnis') {
        const ergebnisFormatted =
          this.ergebnisOptions.find(ergebnis => ergebnis.value === value)?.text ?? '-';
        return (
          '<span style="padding: 6px; display:flex; align-items:center;justify-content:center; font-size: 1rem" class="badge badge-secondary">' +
          ergebnisFormatted +
          '</span>'
        );
      }

      if (key === 'Faelligkeitsdatum') {
        return (
          '<span style="padding: 6px; display:flex; align-items:center;justify-content:center; font-size: 1rem" class="badge badge-secondary">' +
          format(new Date(value), 'dd.MM.yyyy') +
          '</span>'
        );
      }

      if (key === 'Bearbeiter') {
        const personObject = this.users.find(user => user.id === value);
        const name = personObject ? personObject.givenName + ' ' + personObject.surname : '-';
        return (
          '<span style="padding: 6px; display:flex; align-items:center;justify-content:center; font-size: 1rem" class="badge badge-secondary">' +
          name +
          '</span>'
        );
      }

      if (key === 'Team') {
        const shortenedTeam =
          value.split(' ').length > 1
            ? value
                .split(' ')
                .map(word => word[0])
                .join('')
            : value;

        return (
          '<span style="padding: 6px; display:flex; align-items:center;justify-content:center; font-size: 1rem" class="badge badge-secondary">' +
          shortenedTeam +
          '</span>'
        );
      }

      return value;
    },
  },
};
</script>
<style scoped>
:deep(p) {
  padding: 0;
  margin-bottom: 0;
}
</style>
