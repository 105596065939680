<template>
  <div class="d-flex flex-xl-nowrap flex-lg-wrap align-items-center">
    <b-badge :variant="stateColor">{{ stateString }}</b-badge>
  </div>
</template>
<script>
import configStates from '@/core/common/states.config.json';
export default {
  props: {
    state: { type: String, required: true },
  },
  computed: {
    stateString() {
      return this.state ? this.$t('VORGAENGE.blankStates.' + this.state) : this.state;
    },
    stateColor() {
      return this.state ? configStates['vorgaenge'][this.state]?.color : 'secondary' || 'secondary';
    },
  },
};
</script>
