<template>
  <div>
    <VorgangTable
      v-if="vorgaenge"
      :items="vorgaenge"
      :isLoading="isLoading"
      :isOutlined="true"
      :displayFieldsByKey="['id', 'reisezeitraum', 'kunde', 'npx', 'brutto']"
      :dokumenteCheckRequired="dokumenteCheckRequired"
      :passdatenCheckRequired="passdatenCheckRequired"
      releaseprocessActions1Label="Teilnehmernamen"
      releaseprocessActions2Label="Passdaten"
      releaseprocessActions3Label="Dokumente"
    >
      <template v-slot:vorgangDetails="data">
        <div class="bg-gray-100 border-bottom border-dark ml-10 mb-2">
          <b-table
            small
            bordered
            table-class="table table-standard mb-0"
            id="vorgaengeDetailsTableTeilnehmer"
            thead-tr-class="th-no-padding-top"
            :items="data.teilnehmer"
            :fields="[
              { key: 'name', label: 'Name', tdClass: 'cw-30' },
              { key: 'geburtsdatum', label: 'Geburtsdatum', tdClass: 'cw-5' },
              { key: 'nationalitaet', label: 'Nationalität', tdClass: 'cw-5' },
              { key: 'passdaten', class: 'passdaten-col', tdClass: 'cw-60' },
            ]"
          >
            <template #cell(name)="row">
              <b-icon-person-fill></b-icon-person-fill>
              <b>{{ row.item.name }}</b>
              <PassCheckIcon
                v-if="konfiguration"
                :passdatenAttribute="{ required: false, provided: row.item.name }"
              ></PassCheckIcon>
            </template>
            <template #cell(geburtsdatum)="row">
              {{ row.item.geburtsdatum.value | date }}
              <PassCheckIcon v-if="konfiguration" :passdatenAttribute="row.item.geburtsdatum"></PassCheckIcon>
            </template>
            <template #cell(nationalitaet)="row">
              {{ row.item.nationalitaet.value }}
              <PassCheckIcon
                v-if="konfiguration"
                :passdatenAttribute="row.item.nationalitaet"
              ></PassCheckIcon>
            </template>
            <template #cell(passdaten)="row">
              <PassDatenTable :teilnehmer="row.item"></PassDatenTable>
            </template>
          </b-table>
        </div>
      </template>
    </VorgangTable>
  </div>
</template>

<script>
import PassCheckIcon from '@/components/vorgaenge/passdaten-check-icon.vue';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import PassDatenTable from '@/components/vorgaenge/passdaten-table.vue';

export default {
  components: { VorgangTable, PassDatenTable, PassCheckIcon },
  props: {
    isLoading: { type: Boolean },
    vorgaenge: { type: Array },
    passdatenCheckRequired: { type: Boolean },
    dokumenteCheckRequired: { type: Boolean },
    konfiguration: { type: Object },
  },
  methods: {
    parseDate(date) {
      return date ? this.$options.filters.date(date) : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.passdaten-col) {
  width: 50rem;
}
:deep(.th-no-padding-top > th) {
  padding-top: 0px;
}
:deep(.th-no-padding-top > th > div) {
  color: #b5b5c3;
}
:deep(.cw-5) {
  width: 5%;
}
:deep(.cw-10) {
  width: 10%;
}
:deep(.cw-30) {
  width: 30%;
}
:deep(.cw-60) {
  width: 60%;
}
</style>
