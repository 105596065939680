<template>
  <Multiselect
    :value="getValue"
    :options="options"
    selectLabel=""
    selectGroupLabel=""
    deselectLabel=""
    deselectGroupLabel=""
    selectedLabel="Ausgewählt"
    label="text"
    track-by="value"
    :placeholder="placeholder || 'Suchen...'"
    :disabled="disabled"
    @input="emitInputEvent"
    :style="cssProps"
  ></Multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'users-multiselect',
  components: {
    Multiselect,
  },
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    placeholder: { type: String },
    fontSize: { type: String },
    backgroundColor: { type: String },
    borderColor: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    getValue() {
      return this.options.find(option => option.value === this.value);
    },
    cssProps() {
      return {
        '--font-size': this.fontSize || '1rem',
        '--background-color': this.backgroundColor || 'white',
        '--border-color': this.borderColor || '',
      };
    },
  },
  methods: {
    emitInputEvent($event) {
      this.$emit('input', $event.value);
    },
  },
};
</script>
<style scoped>
:deep(.multiselect__tags) {
  min-height: 36px;
  border-radius: 8px;
  border: none;
  margin-left: 4px;
  background-color: var(--background-color);
}
:deep(.multiselect__single) {
  font-size: var(--font-size);
  background-color: var(--background-color);
  opacity: 1;
}
:deep(.multiselect__select) {
  height: 36px;
  border-radius: 8px;
  margin-top: 2px;
}
.multiselect {
  border: 1px solid var(--border-color);
  background-color: var(--background-color) !important;
  min-height: 36px;
}
</style>
