<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="isTerminNach24">
        <ReiseterminViewCardFlugdaten :reiseterminObj="reiseterminObj"></ReiseterminViewCardFlugdaten>
      </div>
      <div class="col-md-12" v-if="isTerminIn24">
        <ReiseterminViewCardSheetdata :reiseterminObj="reiseterminObj" />
      </div>
      <div class="col-md-6">
        <ReiseterminCardAufgaben :reiseterminObj="reiseterminObj"></ReiseterminCardAufgaben>
      </div>
      <div class="col-md-6">
        <template v-if="reiseterminObj">
          <ReiseterminComments
            :reiseterminId="reiseterminObj.id"
            :comments="getCommentsWithMappedAnswers"
            :showDepartmentButtons="true"
            :departmentsOfCommentCreators="departmentsOfCommentCreators"
            :selectedDepartment="selectedDepartment"
            @updateComments="onUpdateComments"
            @changeDepartment="changeDepartment"
          />
          <!-- <ReiseterminCardCommentBase
            :reisekuerzel="reiseterminObj.reise.reisekuerzel"
            :reiseterminId="reiseterminObj.id"
            :startdatum="reiseterminObj.startdatum"
            :initalComments="reiseterminObj.comments"
            :reiseterminObj="reiseterminObj"
            :showDepartmentButtons="true"
            :isInputFieldExpanded="false"
          ></ReiseterminCardCommentBase> -->
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header">
              <b-skeleton class="m-auto" height="100%" width="100%"></b-skeleton>
            </div>
            <div class="card-body">
              <b-skeleton class="m-auto" height="200px" width="100%"></b-skeleton>
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12 gutter-b" id="reiseterminCardVorgaengeDetails">
        <template v-if="reiseterminObj">
          <div>
            <ReiseterminCardVorgaengeDetails
              :defaultTab="2"
              :reiseterminObj="reiseterminObj"
              :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung || isInTransferplanung"
              :isInTransferplanung="isInTransferplanung"
              :gueltigeKonfiguration="reiseterminObj?.gueltigeKonfiguration"
            ></ReiseterminCardVorgaengeDetails>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.more-btn {
  cursor: pointer;
}
</style>

<script>
import ReiseterminCardVorgaengeDetails from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-details.vue';
import ReiseterminViewCardFlugdaten from '@/components/produkte/reisetermine/reisetermin-card-flugdata.vue';
import ReiseterminViewCardSheetdata from '@/components/produkte/reisetermine/reisetermin-card-sheetdata.vue';
import ReiseterminCardAufgaben from '@/components/produkte/reisetermine/reisetermin-card-aufgaben.vue';
import ReiseterminComments from '@/components/comments/reisetermin-comments.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ReisetermineViewComponent',
  components: {
    ReiseterminCardVorgaengeDetails,
    ReiseterminViewCardFlugdaten,
    ReiseterminViewCardSheetdata,
    ReiseterminCardAufgaben,
    ReiseterminComments,
  },
  props: {
    reiseterminObj: Object,
  },
  data() {
    return {
      selectedDepartment: null,
    };
  },
  watch: {
    reiseterminObj() {
      if (this.$route.fullPath.includes('releasepruefung')) {
        if (this.departmentsOfCommentCreators.includes('Destination Operations Management')) {
          this.selectedDepartment = 'DOM';
        } else {
          this.selectedDepartment = null;
        }
      }
    },
  },
  mounted() {
    if (this.$route.fullPath.includes('releasepruefung')) {
      if (this.departmentsOfCommentCreators.includes('Destination Operations Management')) {
        this.selectedDepartment = 'DOM';
      } else {
        this.selectedDepartment = null;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getYieldTeamUsersIds',
      'getPmTeamUsersIds',
      'getDomTeamUsersIds',
      'getFomTeamUsersIds',
      'getCsmTeamUsersIds',
      'getTechTeamUsersIds',
      'getStammdatenTeamUsersIds',
    ]),
    ...mapState({
      users: state => state.users['users'],
    }),
    filteredComments() {
      if (this.selectedDepartment) {
        return this.reiseterminObj?.comments.filter(comment => {
          const { creator } = comment;

          if (this.selectedDepartment === 'PM') {
            return this.getPmTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'Y') {
            return this.getYieldTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'DOM') {
            return this.getDomTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'FOM') {
            return this.getFomTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'CSM') {
            return this.getCsmTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'Tech') {
            return this.getTechTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'STD') {
            return this.getStammdatenTeamUsersIds.includes(creator);
          }
          return false;
        });
      }
      return this.reiseterminObj?.comments ?? [];
    },
    departmentsOfCommentCreators() {
      // überprüfung, ob das comments array leer ist
      if (this.reiseterminObj?.comments?.length === 0) {
        return [];
      } else {
        // es werden sich alle creator aus den kommentaren geholt -> geht über das gesamte array von Objects
        // ergebnis ist ein neues array mit den IDs
        const creatorIds = this.reiseterminObj?.comments.map(comment => comment.creator);

        // geht über das users Array und überprüft, ob die ID in dem creators array enthalten ist
        const usersList = this.users.filter(user => creatorIds.includes(user.id));

        // geht über jedes usersList array und nimmt sich da dann das department heraus
        const departments = usersList.map(user => user.department);
        // es wird dann ein Set daraus gemacht, damit nur unique departments gespeichert werden
        return [...new Set(departments)];
      }
    },
    getCommentsWithMappedAnswers() {
      if (!this.reiseterminObj || !this.reiseterminObj.comments?.length > 0) {
        return [];
      }

      const answers = this.filteredComments.filter(comment => comment.parentId !== null);
      const commentsWithAnswers = this.filteredComments
        .filter(comment => comment.parentId === null)
        .map(comment => {
          const matchingAnswers = answers.filter(answer => answer.parentId === comment.id);
          if (matchingAnswers.length > 0) {
            return { ...comment, answers: matchingAnswers };
          } else return { ...comment, answers: [] };
        })
        .sort((a, b) => new Date(b.createdDateUtc) - new Date(a.createdDateUtc));
      return commentsWithAnswers;
    },
    isTerminNach24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2025;
    },
    isTerminIn24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2024;
    },
    isReiseterminInReleasePruefung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
    isInTransferplanung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
  },
  methods: {
    onUpdateComments(res) {
      this.reiseterminObj.comments = res.comments;
    },
    changeDepartment(departmentLabel) {
      this.selectedDepartment = this.selectedDepartment === departmentLabel ? null : departmentLabel;
    },
  },
};
</script>
