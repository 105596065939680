var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('ActionButton',{directives:[{name:"b-toggle",rawName:"v-b-toggle.aufgaben-collapse",modifiers:{"aufgaben-collapse":true}}],staticClass:"mr-3",attrs:{"title":"Ausklappen","iconClass":_vm.isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'},on:{"click":_vm.toggleCollapse}}),_c('h3',{staticClass:"card-label"},[_vm._v("Aufgaben")]),_c('b-form-checkbox',{staticStyle:{"display":"flex","font-size":"14px"},attrs:{"switch":""},model:{value:(_vm.alleAufgabenAnzeigen),callback:function ($$v) {_vm.alleAufgabenAnzeigen=$$v},expression:"alleAufgabenAnzeigen"}},[_vm._v(" Mit erledigten Aufgaben ")])],1),_c('div',{staticClass:"card-toolbar"},_vm._l((_vm.departmentsOfAufgabenBearbeiter),function(department,index){return _c('b-button',{key:index,staticClass:"px-1 mx-1",class:`border-${_vm.departmentButtonClass(department).border}`,staticStyle:{"height":"calc(1.5em + 1.3rem + 2px)","width":"calc(1.5em + 1.3rem + 2px)","font-weight":"bold","font-size":"0.8rem"},style:({
          'background-color':
            _vm.selectedDepartment === _vm.departmentButtonClass(department).label
              ? _vm.departmentButtonClass(department).color
              : '',
          'border-color': _vm.departmentButtonClass(department).color,
          color:
            _vm.selectedDepartment === _vm.departmentButtonClass(department).label
              ? _vm.departmentButtonClass(department).fontColor
              : '',
        }),on:{"click":function($event){_vm.changeDepartment(_vm.departmentButtonClass(department).label)}}},[_vm._v(" "+_vm._s(_vm.departmentButtonClass(department).label)+" ")])}),1)]),_c('b-collapse',{attrs:{"visible":"","id":"aufgaben-collapse"}},[(_vm.reiseterminObj)?_c('div',{staticClass:"card-body"},[(_vm.reiseterminObj?.aufgaben.length > 0)?_c('div',[_c('AufgabenWidget',{attrs:{"aufgaben":_vm.selectedDepartmentAufgaben,"ansicht":"reisetermine-view","reiseterminObj":_vm.reiseterminObj,"onPlusSignClicked":_vm.plusSignClicked},on:{"clickAufgabenWidget":_vm.onClickAufgabenWidget}})],1):_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v("Keine Aufgaben vorhanden")]),_c('AufgabeDetailsModal',{attrs:{"showAttachments":false},on:{"aufgabeBearbeitet":_vm.onAufgabeBearbeitet}}),_c('AufgabeErstellenModal',{attrs:{"prefixedVerlinkungen":_vm.aufgabenVerlinkung}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }