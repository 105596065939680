<template>
  <div class="">
    <div
      v-for="(aufgabe, id) in aufgabenData"
      :key="'aufgabe' + aufgabe.id"
      style="display: flex; gap: 8px; margin: 8px 0px"
    >
      <b-badge
        @click="onClickBadge(aufgabe)"
        class="hoverAufgabenWidget"
        style="display: flex; gap: 8px; align-items: center"
      >
        <span>
          {{ id + 1 }}.
          {{
            aufgabe.kategorieLabel?.length > 30
              ? aufgabe.kategorieLabel.substring(0, 30) + '...'
              : aufgabe.kategorieLabel
          }}
        </span>
        <span
          style="width: 24px; background-color: #888"
          v-if="aufgabe?.status === 'Pending'"
          class="badge badge-secondary m-0"
        >
          <i class="fas fa-sm fa-clock" style="color: #fff"></i>
        </span>
        <span style="width: 24px" v-else-if="aufgabe?.status === 'Offen'" class="badge badge-danger m-0">
          <i class="fas fa-sm fa-exclamation" style="color: #fff"></i>
        </span>
        <span style="width: 24px" v-else-if="aufgabe?.status === 'In Arbeit'" class="badge badge-warning">
          <i class="far fa-sm fa-clock" style="color: #fff"></i>
        </span>
        <span style="width: 24px" v-else-if="aufgabe?.status === 'Erledigt'" class="badge badge-success">
          <i class="fas fa-sm fa-check" style="color: #fff"></i>
        </span>

        <span>{{ aufgabe.bearbeiter ? username(aufgabe.bearbeiter) : getTeamLabel(aufgabe.team) }}</span>
        <b-badge
          v-if="aufgabe.status !== 'Erledigt'"
          :variant="isFaelligkeitInPast(aufgabe.faelligkeitsdatum) ? 'danger' : 'light'"
          class="ml-1"
        >
          {{ aufgabe.faelligkeitFormatted }} ({{ aufgabe.werktageBisFaelligkeit }})
        </b-badge>
      </b-badge>
      <b-badge
        v-if="
          (ansicht === 'zvc-overview' ||
            ansicht === 'reisetermine-view' ||
            ansicht === 'reisetermin-view-releasepruefung') &&
          id === aufgabenData.length - 1
        "
        :variant="'secondary'"
        class="plus"
        @click="plusSignClicked(aufgabe)"
        style="font-size: 1.2rem; display: inline"
      >
        +
      </b-badge>
    </div>
  </div>
</template>
<script>
import { parseISO, format, isBefore } from 'date-fns';
import { SET_AUFGABEN_VERLINKUNG } from '@/core/produkte/reisetermine/stores/zvc.module';
import {
  flugstammdatenAufgabenKategorien,
  flugplanungAufgabenKategorien,
} from '@/core/common/helpers/utils.js';
import { mapGetters } from 'vuex';
export default {
  props: {
    aufgaben: { type: Array, required: true },
    filter: { type: String },
    ansicht: { type: String },
    onPlusSignClicked: { type: Function, default: () => {} },
    data: { type: Object, default: null },
    openAufgabenId: { type: Number, default: null },
    reiseterminObj: { type: Object, default: null },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getKategorienDestinationYield', 'getKategorienLabel', 'getKategorienReleaseprozess']),
    aufgabenData() {
      const formattedAufgaben = this.aufgaben
        .map(aufgabe => ({
          ...aufgabe,
          kategorieLabel: this.getKategorienLabel(aufgabe.kategorie),
          status: aufgabe.status === 'InArbeit' ? 'In Arbeit' : aufgabe.status,
          faelligkeitFormatted: format(parseISO(aufgabe.faelligkeitsdatum), 'dd.MM.'),
        }))
        .filter(aufgabe => this.openAufgabenId == null || aufgabe.id != this.openAufgabenId);
      if (this.filter === 'releaseprozess') {
        const releaseprozessAufgabenKategorienLabels = this.getKategorienReleaseprozess.map(
          kategorie => kategorie.aufgabenKategorie
        );
        return formattedAufgaben.filter(
          aufgabe =>
            releaseprozessAufgabenKategorienLabels.includes(aufgabe.kategorie) ||
            this.getKategorienDestinationYield.includes(aufgabe.kategorie)
        );
      } else if (this.filter === 'zvc-overview') {
        return formattedAufgaben.filter(aufgabe =>
          this.getKategorienDestinationYield.includes(aufgabe.kategorie)
        );
      } else if (this.filter === 'flugstammdaten') {
        return formattedAufgaben.filter(
          aufgabe =>
            flugstammdatenAufgabenKategorien
              .filter(kategorie => kategorie !== 'NPruefung')
              .includes(aufgabe.kategorie) ||
            (aufgabe.kategorie === 'NPruefung' && !aufgabe.beschreibung.includes('+'))
        );
      } else if (this.filter === 'flugplanung') {
        return formattedAufgaben.filter(aufgabe => flugplanungAufgabenKategorien.includes(aufgabe.kategorie));
      } else {
        return formattedAufgaben;
      }
    },
  },
  methods: {
    onClickBadge(aufgabe) {
      if (
        this.ansicht === 'releaseprozess-overview' ||
        this.ansicht === 'fvc-overview' ||
        this.ansicht === 'zvc-overview' ||
        this.ansicht === 'sidebar-flugplanung' ||
        this.ansicht === 'sidebar-terminplanung' ||
        this.ansicht === 'flugstammdaten-overview' ||
        this.ansicht === 'aufgabe-details' ||
        this.ansicht === 'flugplanung-overview'
      ) {
        const routeData = this.$router.resolve({ path: '/aufgaben/overview?aufgabenId=' + aufgabe.id });
        window.open(routeData.href, '_blank');
      } else {
        this.$emit('clickAufgabenWidget', aufgabe);
      }
    },
    getTeamLabel(team) {
      if (team.split(' ').length > 1) {
        return team
          .split(' ')
          .map(word => word.substring(0, 1))
          .join('');
      } else return team;
    },
    isFaelligkeitInPast(faelligkeitsdatum) {
      return isBefore(parseISO(faelligkeitsdatum), new Date());
    },
    username(userId) {
      const name = this.$store.getters.getUsernameById(userId);
      const shortenedName = name
        ?.split(' ')
        .map((subName, i) => {
          if (i == 0) return subName;
          else return subName.substring(0, 1) + '.';
        })
        .join(' ');

      return shortenedName;
    },
    plusSignClicked() {
      if (this.ansicht === 'reisetermine-view' || this.ansicht === 'reisetermin-view-releasepruefung') {
        this.$store.commit(SET_AUFGABEN_VERLINKUNG, [
          {
            id: 0,
            typ: 'Reisetermin',
            artifactId: this.reiseterminObj.id,
            artifactText: this.reiseterminObj.reiseterminkuerzel,
          },
        ]);
        if (this.onPlusSignClicked) {
          this.onPlusSignClicked();
        }
      } else {
        console.log('reiseterminObj: ', this.reiseterminObj);
        this.$store.commit(SET_AUFGABEN_VERLINKUNG, [
          {
            id: 0,
            typ: 'Reisetermin',
            artifactId: this.data.id,
            artifactText: this.data.reiseterminkuerzel,
          },
        ]);
        if (this.onPlusSignClicked) {
          this.onPlusSignClicked();
        }
      }
    },
  },
};
</script>
<style scoped>
.hoverAufgabenWidget:hover {
  background-color: #cdcbcb;
  cursor: pointer;
}
.plus:hover {
  background-color: #cdcbcb;
  cursor: pointer;
}
</style>
